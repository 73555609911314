.code_wrapper {
	display: flex;
	flex-direction: column;
	border-radius: 0.4em;
	background-color: var(--bg-color);
	width: fit-content;
	margin: 1.5em 0;
}
.snippet {
	color: var(--primary-color);
	padding: 1em;
	display: flex;
	margin: 0;
}

.viewMore {
	/* position: absolute; */
	background-color: white;
	opacity: 0.7;
	width: 100%;
	text-align: center;
	padding: 0.5em;
}
.viewMore:hover {
	opacity: 0.2;
	cursor: pointer;
}

.copy {
	/* width: 1em;
	height: 1em; */
	background-color: var(--primary-color);
	right: 0;
	top: 0;
	margin-left: 1em;
	color: gray;
	padding: 0.2em 0.3em;
	border-radius: 0.1em;
	height: fit-content;
	cursor: pointer;
}
.copy:hover {
	opacity: 0.5;
}