.sub-heading {
	font-size: 1.5em;
}
.preview {
	display: flex;
	flex-direction: row;
	max-width: 100%;
	width: 40em;
}

img {
	max-width: 100%;
	margin: 0 1em;
}