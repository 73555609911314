:root {
	--primary-color: #fff;
	/* --bg-color: #006064; */
	--bg-color: #082032;
	--content-margin: 1600px;
}
html {
	background-color: var(--bg-color);
	color: var(--bg-color);
	font-family: sans-serif;
}

body {
	max-width: var(--content-margin);
	margin: 0 auto;
}

.container {
	margin: 5em 0;
	padding: 2em;
	background-color: var(--primary-color);
	border-radius: 0.2em;
}
.footer {
	text-align: center;
	color: var(--primary-color);
}

h1 {
	font-size: 2em;
	font-weight: bolder;
}
h2 {
	font-size: 1.7em;
}
h3 {
	font-size: 1.4em;
}
.sub-heading {
	font-size: 1.5em;
}
.txt {
	font-size: 1em;
	font-weight: 400;
	color: var(--bg-color);
}
.txt-cen {
	text-align: center;
}
.txt-invert {
	color: var(--primary-color);
}

.section-header {
	font-size: 1.7em;
	color: var(--bg-color);
}