.next_tutorial {
	font-size: 2em;
	color: var(--bg-color);
	justify-content: flex-end;
	display: flex;
	align-items: center;
}

.next {
	width: 1em;
	height: 1em;
}

.back {
	justify-content: flex-start;
	margin-top: 2em;
}

.rota {
	transform: rotate(180deg);
}